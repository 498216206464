<template>
	<div v-loading="loading">
		<div class="navCommon">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>客户列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="codes">
			<el-input clearable @clear="inputclear" placeholder="请输入客户名称" v-model="codevalues" class="input-with-select">
			    <el-select v-model="selectvalues" slot="prepend" placeholder="请选择实训室类型">
			      <el-option label="全部" :value="0"></el-option>
			      <el-option :key="'qax'+index" v-for="(item,index) in TrainTypedata" :label="item.shixunName" :value="item.id"></el-option>
			    </el-select>
			    <el-button @click='findclick' slot="append" icon="el-icon-search"></el-button>
			  </el-input>
		</div>
		<div class="tables">
			<el-table v-loading="loading" border ref="multipleTable" :data="queryUserdatas" tooltip-effect="dark" style="width: 100%">
				<!-- <el-table-column type="selection" width="55">
				</el-table-column> -->
				<el-table-column type="index" label="序号" width="50">
				</el-table-column>
				<!-- <el-table-column type="expand">
					<template slot-scope="props">
						<div>是否订做：{{ props.row.eqCustomMade }}</div>
						<div>发票、税：{{ props.row.eqInvoice }}</div>
						<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
						<div v-if="props.row.eqCustom" v-for="(item,index) in JSON.parse(props.row.eqCustom)">
							{{item.name}}：{{ item.value }}
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="useproCustomerName" label="姓名">
				</el-table-column>
				<el-table-column prop="useproCustomerTel" label="手机号">
				</el-table-column>
				<el-table-column prop="useproCustomerTel" label="实训室类型">
					<template slot-scope="scope">
						{{scope.row.shixunName}}
					</template>
				</el-table-column>
				<el-table-column label="提交时间">
					<template slot-scope="scope">
						{{moment(scope.row.useproCreateTime).format('YYYY-MM-DD HH:mm:SS')}}
					</template>
				</el-table-column>
				<el-table-column label="优先级">
					<template slot-scope="scope">
						{{scope.row.useproPower == 0 ? '低':scope.row.useproPower == 1?'中':'高'}}
					</template>
				</el-table-column>
				<el-table-column label="备注">
					<template slot-scope="scope">
						{{scope.row.useproDescribe ? scope.row.useproDescribe:'无'}}
					</template>
				</el-table-column>
				<el-table-column label="类型">
					<template slot-scope="scope">
						{{scope.row.useproBudget == 0 ? '按需求':'按预算'}}
						<span v-if="scope.row.useproBudget == 1" style="color: #409eff;margin-left: 15px;">
							{{scope.row.useproSign == 1 ? '0-300万元' : scope.row.useproSign == 2 ?'300-1000万元':'1000万元以上'}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="userName" label="所属人员" v-if="roleId == 1">
				</el-table-column>
				<el-table-column width="120"  label="操作">
					<template slot-scope="scope">
						<el-tooltip v-if="scope.row.useproBudget == 0" content="查看" placement="bottom" effect="light">
							<el-button @click="$router.push({path:'customerdetails',query:{id:scope.row.id}})" size="mini" type="primary" icon="el-icon-search">
							</el-button>
						</el-tooltip>
						<el-tooltip v-if="roleId != 1" content="修改" placement="bottom" effect="light">
							<el-button @click="dialogVisible = true,planId=scope.row.id,radiovalue = scope.row.useproPower,inputvalue = scope.row.useproDescribe" size="mini" type="primary" icon="el-icon-edit">
							</el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination" v-if="totalnumber>10">
				<el-pagination
				:page-size="10"
				:current-page="pagenum"
				@current-change="paginationchange"
				  background
				  layout="prev, pager, next"
				  :total="totalnumber">
				</el-pagination>
			</div>
		</div>
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible">
			<el-button type="primary">优先级 </el-button>
			<div class="tebody">
				<el-radio-group v-model="radiovalue">
					<el-radio :label="0">低</el-radio>
					<el-radio :label="1">中</el-radio>
					<el-radio :label="2">高</el-radio>
				</el-radio-group>
			</div>
			<el-button type="primary">备注 </el-button>
			<div class="tebody">
				<el-input type="textarea" maxlength="200" show-word-limit v-model="inputvalue" placeholder="请输入内容">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
			    <el-button type="primary" @click="appointData">确 定</el-button>
			  </span>
		</el-dialog>
	</div>
</template>

<script>
	import Moment from 'moment'
	export default {
		components: {},
		data() {
			return {
				loading:false,
				moment: Moment,
				codevalues:'',
				selectvalues:0,
				dialogVisible:false,
				radiovalue:1,
				inputvalue:"",
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				queryUserdatas: [],
				TrainTypedata: [],
				pagenum:1,
				totalnumber:'',
				datalisttype:0,
				planId:''
			}
		},
		created() {
			this.getqueryUserDemand()
			this.getqueryTrainType()
		},
		mounted() {

		},
		methods: {
			//设置优先级备注
			appointData(){
				let datas = {}
				if(this.radiovalue){datas.powerId = this.radiovalue}
				if(this.inputvalue){datas.describe = this.inputvalue}
				datas.planId = this.planId
				this.$post('updateAppointData',datas).then((res)=>{
					this.$message({
						message: '修改成功',
						type: 'success'
					});
					this.dialogVisible = false
					this.getqueryUserDemand()
				})
			},
			//清除搜索
			inputclear(){
				this.datalisttype = 0
				this.pagenum = 1
				this.selectvalues = 0
				this.getqueryUserDemand()
			},
			//分页
			paginationchange(val){
				this.pagenum = val
				if(this.datalisttype == 0){
					this.getqueryUserDemand()
				}else{
					this.findcodes()
				}
				
			},
			getqueryTrainType(){
				this.$get('queryTrainType',).then((res)=>{
					this.TrainTypedata = res
				})
			},
			async getqueryUserDemand(){
				this.queryUserdatas = []
				this.totalnumber = 0
				this.loading = true
				await this.$post('queryUserDemand',{
					saleId:this.userId,
					pagenum: this.pagenum,
					pagesize: 10,
				}).then((res)=>{
					this.queryUserdatas = res.list
					this.totalnumber = res.total
				this.loading = false
				})
				this.$nextTick(()=>{
					this.loading = false
				})
			},
			//点击搜索
			findclick(){
				this.datalisttype = 1
				this.pagenum = 1
				if(this.codevalues){
					this.findcodes()
				}else{
					this.inputclear()
					// this.$message({
					// 	message: '请输入关键字',
					// 	type: 'warning'
					// });
				}
			},
			//搜索
			findcodes(){
				this.totalnumber = []
				this.totalnumber = 0
				this.loading = true
				this.$post('queryByAppoint',{
					saleId:this.userId,
					typeId:this.selectvalues,
					userName:this.codevalues,
					pagenum: this.pagenum,
					pagesize: 10,
				}).then((res)=>{
					this.loading = false
					this.queryUserdatas = res.list
					this.totalnumber = res.total
				})
				
			}
		}
	}
</script>

<style scoped lang="less">
	.tables{
		margin: 40px 0;
	}
	.tebody{
		margin: 40px 0 ;
	}
	.codes{
		width: 60%;
		margin: 40px 0;
	}
	.pagination{
		text-align: center;
		margin: 40px 0;
	}
</style>
<style>
	.codes .el-input-group__prepend{
		width: 18%;
	}
</style>
